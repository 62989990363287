export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL, //(string): The URL of the OIDC provider.
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: process.env.REACT_APP_REDIRECT_URL, //The URI of your client application to receive a response from the OIDC provider.
  login: process.env.REACT_APP_AUTH_URL + "/login",
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL,
  response_type: "code", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  scope: "openid profile Api", //(string, default: 'openid'): The scope being requested from the OIDC provider.
  automaticSilentRenew: true,
};

export const CONFIG = {
  API_URL: process.env.REACT_APP_API_URL,
};
