import React, { Suspense, useEffect, useContext, useState } from "react";
import {
  MuiThemeProvider,
  CssBaseline,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Router from "./routes";
import NavBar from "./components/NavBar";
import { BrowserRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import * as actions from "./redux/actions";
import { AuthContext } from "./auth/AuthProvider";
import Toast from "./components/FormUI/Toast";
import { useIdleTimer } from "react-idle-timer";
import CacheBuster from "react-cache-buster";
import * as packageInfo from "../package.json";
import { getVehicleStatusCount } from "./api";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const App = ({
  loadLookupLists,
  loadLocations,
  loadVehicleTypes,
  loadUserProfile,
  user,
  loadClientInfo,
}) => {
  const auth = useContext(AuthContext);

  const features = useSelector((state) => state.lookupLists.features);

  useEffect(() => {
    async function fetchData() {
      const profile = auth.getProfile();
      if (profile) {
        await loadUserProfile(
          { clientId: profile.clientId, userId: profile.userId },
          auth.getToken()
        );
        await loadLookupLists(
          profile.clientId,
          auth.getToken(),
          profile.userId
        );
        await loadLocations(profile.clientId, profile.userId, auth.getToken());
        await loadVehicleTypes(
          profile.clientId,
          profile.userId,
          auth.getToken()
        );
        await loadClientInfo(profile.clientId, auth.getToken());

        let data = await getVehicleStatusCount(
          {
            clientId: profile.clientId,
            userId: profile.userId,
            locationId: 0,
            vehicleTypeId: 0,
          },
          auth.getToken()
        );

        let noOfVehicles = 0;

        if (data) {
          data?.forEach((status) => {
            noOfVehicles = noOfVehicles + status.total || 0;
          });

          setTotalNoOfVehicles(noOfVehicles);
        }
      }
    }
    fetchData();
  }, [auth, loadLocations]);

  const handleOnIdle = () => {
    auth.logout();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 20,
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: true,
  });

  const classes = useStyles();

  const isProduction = process.env.NODE_ENV === "production";

  let pkgInfo = packageInfo;

  useEffect(() => {
    const conString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING;

    if (conString) {
      var reactPlugin = new ReactPlugin();

      const appInsights = new ApplicationInsights({
        config: {
          connectionString: conString,
          enableAutoRouteTracking: true,
          extensions: [reactPlugin],
        },
      });
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    }

    const userPreferenceOfFilterButtonVisibility = localStorage.getItem(
      "rentallFilterBtnVisibilityPref"
    );

    if (!userPreferenceOfFilterButtonVisibility) {
      localStorage.setItem(
        "rentallFilterBtnVisibilityPref",
        JSON.stringify({
          agreement: true,
          reservation: true,
          vehicle: true,
          customer: true,
        })
      );
    }
  }, []);

  const hideNav = window.location.href.indexOf("DamageReport") >= 0;

  const [totalNoOfVehicles, setTotalNoOfVehicles] = useState(0);

  const isSquarePaymentGateWay =
    String(
      features?.find((feature) => feature.featureName === "PROCESS_PAYMENT")
        ?.value || ""
    ).toLowerCase() === "squareup";

  useEffect(() => {
    if (isSquarePaymentGateWay) {
      window.sqGatewayLoaded = false;
      let sqPaymentScript = document.createElement("script");
      sqPaymentScript.src = process.env.REACT_APP_SQUARE_UP_BASE_URL;
      sqPaymentScript.type = "text/javascript";
      sqPaymentScript.async = false;
      sqPaymentScript.onload = () => {
        window.sqGatewayLoaded = true;
      };
      document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    }
    if (isSquarePaymentGateWay) {
      window.sqGatewayLoaded = false;
      let sqPaymentScript = document.createElement("script");
      sqPaymentScript.src = process.env.REACT_APP_SQUARE_UP_CARD_ELEMEMT_URL;
      sqPaymentScript.type = "text/javascript";
      sqPaymentScript.async = false;
      sqPaymentScript.onload = () => {
        window.sqGatewayLoaded = true;
      };
      document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    }
  }, [isSquarePaymentGateWay]);

  return (
    <CacheBuster
      currentVersion={pkgInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <BrowserRouter>
          <Suspense
            fallback={
              <Backdrop open={true}>
                <CircularProgress color="primary" size={60} />
              </Backdrop>
            }
          >
            <div className={classes.root}>
              {auth.isAuthenticated() && !hideNav && <NavBar user={user} />}
              <Router />
              <Toast />
            </div>
          </Suspense>
        </BrowserRouter>
      </MuiThemeProvider>
    </CacheBuster>
  );
};

const mapDispatchToProps = {
  loadLookupLists: actions.loadLookupLists,
  loadUserProfile: actions.loadUserProfile,
  loadLocations: actions.loadLocations,
  loadVehicleTypes: actions.loadVehicleTypes,
  loadClientInfo: actions.loadClientInfo,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
